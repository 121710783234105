import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAt,
  where,
} from "firebase/firestore";
import { db } from "../firebase-config";

export const addUserInfo = async (uid, body) => {
  await setDoc(
    doc(db, "users", uid),
    {
      ...body,
    },
    { merge: true }
  );
  return "OK";
};
//   export const getAllCoupons = async () => {
//     const snapshot = await getDocs(query(collection(db, "coupons")));
//     return snapshot.size;
//   };

//   export async function deleteCoupon(idCoupon) {
//     const docRef = doc(db, "coupons", idCoupon);
//     return deleteDoc(docRef);
//   }

//   export const getCouponLists = async () => {
//     // const { size } = await getDocs(query(collection(db, "coupons")));
//     const querySnapshot = await getDocs(
//       query(collection(db, "coupons"), orderBy("createdAt", "asc"), limit(2))
//     );
//     return querySnapshot;
//   };
