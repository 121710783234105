import { useContext, createContext, useEffect, useState } from "react";
import LoadingSection from "../components/LoadingSection";
import { db, auth } from "../firebase-config";
import {
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  query,
  collection,
  deleteDoc,
} from "firebase/firestore";
import { UserAuth } from "./AuthContext";

const VendorContext = createContext();

export function VendorContextProvider({ children }) {
  const { user } = UserAuth();
  const [userData, setUserData] = useState("");

  const docRef = collection(db, "users", user.uid, "vendors");

  async function addVendors(name, slug) {
    return await setDoc(
      doc(db, "users", user.uid, "vendors", slug),
      {
        name: name,
      },
      { merge: true }
    );
  }
  async function delVendors(slug) {
    return await deleteDoc(doc(db, "users", user.uid, "vendors", slug));
  }

  useEffect(() => {
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      setUserData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    return () => {
      unsubscribe();
    };
  }, [user.uid]);

  return (
    <VendorContext.Provider value={{ userData, addVendors, delVendors }}>
      {children}
    </VendorContext.Provider>
  );
}

export function UserVendors() {
  return useContext(VendorContext);
}
