import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
export const dateNow = () => {
  return dayjs().format("YYYY-MM-DD");
};
export const nowYYYYMM = () => {
  return dayjs().format("YYYY-MM");
};
export const nowYYYY = () => {
  return dayjs().format("YYYY");
};
export const nowYYYYMMDD = () => {
  return dayjs().format("YYYYMMDD");
};
export const formatDateString = (value) => {
  return dayjs(value).format("YYYY-MM-DD");
};
export const formatMonthYear = (value) => {
  return dayjs(value).format("MMM, YYYY");
};
export const formatFullMonthYear = (value) => {
  return dayjs(value).format("MMMM, YYYY");
};
export const formatFullDate = (value) => {
  return dayjs(value).format("MMMM DD, YYYY");
};
export const formatFullDateAndTime = (value) => {
  return dayjs(value).format("MMMM DD, YYYY HH:mm");
};

export const dateFromNow = (value) => {
  return dayjs(value).fromNow(true);
};
export const dateToNow = (value) => {
  return dayjs(value).toNow(true);
};
export const formatMonthShort = (value) => {
  return dayjs(value).format("MMM DD, YYYY");
};
export const formatDay = (value) => {
  return dayjs(value).format("ddd");
};
export const formatDateOfMonth = (value) => {
  return dayjs(value).format("DD");
};
export const formatMonth = (value) => {
  return dayjs(value).format("MMM");
};
export const formatYear = (value) => {
  return dayjs(value).format("YYYY");
};
export const formatTime = (value) => {
  return dayjs(value).format("HH:mm");
};
export const dateAfterWeek = () => {
  return dayjs().add(1, "week").format("YYYY-MM-DD");
};
export const dateIsAfter = (value) => {
  const nowDayjs = dayjs();
  return dayjs(value).isAfter(nowDayjs.toISOString());
};
