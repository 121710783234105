import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const UserProject = ({ children }) => {
  const { user } = UserAuth();

  // if (user) {
  //   return <Navigate to={`/project/${"ss"}`} />;
  // }
  return children;
};

export default UserProject;
