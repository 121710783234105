import { useContext, createContext, useEffect, useState } from "react";
import LoadingSection from "../components/LoadingSection";
import { db, auth } from "../firebase-config";
import {
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  query,
  collection,
  deleteDoc,
} from "firebase/firestore";
import { UserAuth } from "./AuthContext";
import { dateIsAfter } from "../utils/dateFormat";

const SubscriptionContext = createContext();

export function SubscriptionContextProvider({ children }) {
  const { user } = UserAuth();
  const [isActive, setIsActive] = useState(false);
  const [validUntil, setValidUntil] = useState("");

  const docRef = doc(db, "users", user.uid);

  useEffect(() => {
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.data().valid_until) {
        if (dateIsAfter(doc.data().valid_until.toDate()) === true) {
          setIsActive(true);
          setValidUntil(doc.data().valid_until.toDate());
        } else {
          setIsActive(false);
        }
      } else {
        setIsActive(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [user.uid]);

  return (
    <SubscriptionContext.Provider value={{ isActive, validUntil }}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export function UserSubscription() {
  return useContext(SubscriptionContext);
}
