import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Grid,
  Typography,
  InputAdornment,
  TextField,
  Box,
  Card,
  Chip,
  CardContent,
  IconButton,
} from "@mui/material/";
import LoadingButton from "@mui/lab/LoadingButton";
import { NavLink } from "react-router-dom";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoBrand from "../assets/logo/logo-brand.webp";
import FemaleAnim from "../assets/anim/female.json";
import LoginAnim from "../assets/anim/login.json";

import AlertAuth from "../components/AlertAuth";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

import lottie from "lottie-web";
import { grey, indigo } from "@mui/material/colors";

const Register = () => {
  let navigate = useNavigate();
  const { signUp, logOut, user } = UserAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const container = useRef(null);
  const container2 = useRef(null);

  useEffect(() => {
    if (user != null) {
      // console.log(user);
      navigate("/home");
    }
    const instance = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: LoginAnim,
    });
    const animMale = lottie.loadAnimation({
      container: container2.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: FemaleAnim,
    });
    return () => {
      instance.destroy();
      animMale.destroy();
    };
  }, [user]);

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      await signUp(email, password);
      // await logOut();
      setSuccess("You has been created account successfully");
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        setError("Email already exists. Please use different email");
      }
      if (err.code === "auth/weak-password") {
        setError("Password should be at least 6 characters");
      }
      if (err.code === "auth/invalid-email") {
        setError("Email is invalid. Please input the correct email.");
      }
      // setError(err.message);
    }
    setLoading(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div className="bg-login">
        <Box
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Card
            sx={{
              borderRadius: 4,
              display: "flex",
              width: 900,
              height: 600,
            }}
            elevation={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={"space-between"}
              p={4}
              sx={{
                width: "50%",
                height: "100%",
                backgroundColor: "primary.main",
              }}
            >
              <Box display="flex" justifyContent="start">
                <Chip
                  icon={<ArrowBackIcon color="fourth" />}
                  label="BACK"
                  component={"a"}
                  variant="filled"
                  href="https://weddlist.com"
                  sx={{
                    bgcolor: "fifth.main",
                    fontSize: 16,
                    color: "fourth.main",
                    fontWeight: 800,
                    ":hover": {
                      cursor: "pointer",
                      transform: "translateX(-10px)",
                      transition: "transform 330ms ease-in-out",
                    },
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <div className="container" ref={container}></div>
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                flexDirection={"column"}
              >
                <Typography
                  variant="h4"
                  fontWeight={600}
                  textTransform={"uppercase"}
                  color="white"
                >
                  All In One
                </Typography>
                <Typography variant="h4" fontWeight={300} color="white">
                  Solution For
                </Typography>
                <Typography variant="h5" fontWeight={200} color="white">
                  Wedding & Events
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "50%",
                flexDirection: "column",
                justifyContent: "center",
                bgcolor: "white",
                paddingX: 2,
              }}
            >
              <CardContent>
                <Grid container direction="column" justifyContent="center">
                  <Grid
                    item
                    paddingX={2}
                    paddingBottom={1}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                  >
                    <Box
                      component="img"
                      display="flex"
                      margin="auto"
                      width={200}
                      alt="NOTA Logo"
                      src={LogoBrand}
                    />
                  </Grid>
                  <Grid
                    item
                    paddingX={3}
                    paddingBottom={2}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                  >
                    <Typography variant="body1">
                      Hello. Enter the information below for registering.
                    </Typography>
                  </Grid>
                  {error && (
                    <Grid item paddingX={3} xs={12} sm={6} md={4} lg={3} xl={3}>
                      <AlertAuth status="error" message={error} />
                    </Grid>
                  )}
                  {success && (
                    <Grid item paddingX={3} xs={12} sm={6} md={4} lg={3} xl={3}>
                      <AlertAuth status="success" message={success} />
                    </Grid>
                  )}
                  <Grid item paddingX={3} xs={12} sm={6} md={4} lg={3} xl={3}>
                    <Box width="100%" display="flex" flexDirection="column">
                      <Box
                        width="100%"
                        flexDirection="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextField
                          id="input-with-icon-textfield"
                          label="Email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AlternateEmailIcon />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          variant="outlined"
                          type="email"
                          required={true}
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                        />
                      </Box>
                      <Box
                        marginTop={2}
                        marginBottom={1}
                        width="100%"
                        flexDirection="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextField
                          id="input-with-icon-textfield"
                          label="Password"
                          placeholder="Type your password..."
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff fontSize="small" />
                                  ) : (
                                    <Visibility fontSize="small" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          required={true}
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                          fullWidth
                        />
                      </Box>
                      <Box
                        marginTop={1}
                        marginBottom={2}
                        width="100%"
                        flexDirection="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextField
                          id="input-with-icon-textfield"
                          label="Confirm Password"
                          placeholder="Type your password again..."
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff fontSize="small" />
                                  ) : (
                                    <Visibility fontSize="small" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          required={true}
                          onChange={(event) => {
                            setConfirmPassword(event.target.value);
                          }}
                          fullWidth
                        />
                      </Box>
                    </Box>

                    <LoadingButton
                      onClick={() => handleSignUp()}
                      loading={loading}
                      loadingPosition="center"
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: 40,
                        textTransform: "none",
                        borderRadius: 8,
                        marginBottom: 1,
                        marginTop: 1,
                        color: "#ffffff",
                      }}
                    >
                      Sign up
                    </LoadingButton>

                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"center"}
                      textAlign="center"
                      marginTop={1}
                      gap={1}
                    >
                      <Typography variant="caption">
                        Already have an account?
                      </Typography>

                      <Typography
                        variant="caption"
                        fontWeight={800}
                        textTransform={"uppercase"}
                      >
                        <NavLink
                          to="/"
                          style={{
                            textDecoration: "none",
                            color: indigo["400"],
                          }}
                        >
                          Sign in
                        </NavLink>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          </Card>
        </Box>
      </div>

      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <Grid item paddingX={2} xs={12} sm={6} md={4} lg={3} xl={3}>
          <Box width="100%" margin="auto">
            <div className="container" ref={container2}></div>
          </Box>
        </Grid>
        <Grid
          item
          paddingX={4}
          paddingBottom={2}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
        >
          <Typography variant="h4" fontWeight={600}>
            Sign up
          </Typography>
        </Grid>
        {error && (
          <Grid item paddingX={6} pb={2} xs={12} sm={6} md={4} lg={3} xl={3}>
            <AlertAuth status="error" message={error} />
          </Grid>
        )}
        {success && (
          <Grid item paddingX={6} pb={2} xs={12} sm={6} md={4} lg={3} xl={3}>
            <AlertAuth status="success" message={success} />
          </Grid>
        )}
        <Grid item paddingX={3} xs={12} sm={6} md={4} lg={3} xl={3}>
          <Box width="100%" display="flex" flexDirection="column">
            <Box
              width="100%"
              flexDirection="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <AlternateEmailIcon sx={{ width: "10%", marginRight: 1 }} />
              <TextField
                id="input-with-icon-textfield"
                placeholder="Email Address"
                sx={{ width: "90%" }}
                fullWidth
                variant="standard"
                type="email"
                required={true}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Box>
            <Box
              marginTop={3}
              marginBottom={1}
              width="100%"
              flexDirection="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <LockIcon sx={{ width: "10%", marginRight: 1 }} />
              <TextField
                placeholder="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "90%" }}
                variant="standard"
                type={showPassword ? "text" : "password"}
                required={true}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                fullWidth
              />
            </Box>
            <Box
              marginTop={2}
              marginBottom={2}
              width="100%"
              flexDirection="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <LockIcon sx={{ width: "10%", marginRight: 1 }} />
              <TextField
                placeholder="Confirm Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "90%" }}
                variant="standard"
                type={showPassword ? "text" : "password"}
                required={true}
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                }}
                fullWidth
              />
            </Box>
          </Box>

          <LoadingButton
            onClick={() => handleSignUp()}
            loading={loading}
            loadingPosition="center"
            variant="contained"
            sx={{
              width: "100%",
              height: 40,
              textTransform: "none",
              borderRadius: 8,
              marginBottom: 1,
              marginTop: 2,
              color: "#ffffff",
            }}
          >
            Sign up
          </LoadingButton>

          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            textAlign="center"
            marginTop={1}
            gap={1}
          >
            <Typography variant="caption">Already have an account?</Typography>

            <Typography
              variant="caption"
              fontWeight={800}
              textTransform={"uppercase"}
            >
              <NavLink
                to="/"
                style={{
                  textDecoration: "none",
                  color: indigo["400"],
                }}
              >
                Sign in
              </NavLink>
            </Typography>
          </Box>

          {/* <Box textAlign="center" marginTop={1}>
            <Typography variant="caption">
              Already have an account?
              <NavLink
                to="/"
                style={{
                  textDecoration: "none",
                  fontWeight: 700,
                  color: "#aa00ff",
                }}
              >
                &nbsp;Sign in
              </NavLink>
            </Typography>
          </Box> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Register;
