import { useContext, createContext, useEffect, useState } from "react";
import LoadingSection from "../components/LoadingSection";
import { db, auth } from "../firebase-config";
import { doc, setDoc, getDoc, onSnapshot } from "firebase/firestore";
import { UserAuth } from "./AuthContext";

const InvoiceContext = createContext();

export function InvoiceContextProvider({ children }) {
  const { user } = UserAuth();
  const [profile, setProfile] = useState("");

  async function uploadLogo(url) {
    return await setDoc(
      doc(db, "users", user.uid),
      {
        logo: url,
      },
      { merge: true }
    );
  }

  async function addProfile(body) {
    return await setDoc(
      doc(db, "users", user.uid),
      {
        ...body,
      },
      { merge: true }
    );
  }

  useEffect(() => {
    const docRef = doc(db, "users", user.uid);
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        setProfile(snapshot.data());
      } else {
        setProfile("");
      }
    });
    return () => {
      unsubscribe();
    };
  }, [user.uid]);

  return (
    <InvoiceContext.Provider value={{ profile, uploadLogo, addProfile }}>
      {children}
    </InvoiceContext.Provider>
  );
}

export function UserInvoice() {
  return useContext(InvoiceContext);
}
