import React, { useEffect, useState } from "react";
import { Collapse, Alert, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AlertAuth = ({ status, message, autoClose, onClose }) => {
  const [alert, setAlert] = useState(true);
  console.log("ddd", autoClose);
  useEffect(() => {
    if (autoClose === undefined || autoClose === true) {
      const timer = setTimeout(() => {
        setAlert(false);
        onClose();
      }, 3500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [autoClose]);

  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Collapse in={alert}>
        <Alert
          severity={status}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert(false);
                onClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ width: "100%", borderRadius: 3 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Stack>
  );
};

export default AlertAuth;
