import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box, Button, Container } from "@mui/material";
import Anim from "../../assets/anim/Anim-404.json";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar";
import HomeIcon from "@mui/icons-material/Home";

function ErrorPage() {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Anim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Box flexGrow={1} bgcolor={"abu.main"}>
      <Container maxWidth="xl" sx={{ minHeight: "100dvh" }}>
        <Navbar />
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={{ xs: "column-reverse", md: "row" }}
          justifyContent={"center"}
          gap={4}
        >
          <Box
            flexGrow={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box
              display={{ xs: "none", md: "flex" }}
              flexDirection={"column"}
              gap={4}
            >
              <Typography variant={"h2"} fontWeight={700}>
                We can't find the page <br />
                you are looking for.
              </Typography>
              <Typography variant="h5" color={"gray"}>
                You can get back to the Home page <br />
                by clicking the button below.
              </Typography>
              <Box>
                <Button
                  sx={{ borderRadius: 6 }}
                  size="large"
                  startIcon={<HomeIcon />}
                  onClick={() => {
                    navigate("/home");
                  }}
                  variant="contained"
                >
                  Back to Home
                </Button>
              </Box>
            </Box>
            <Box
              display={{ xs: "flex", md: "none" }}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={2}
            >
              <Typography variant={"h4"} textAlign={"center"} fontWeight={700}>
                We can't find the page <br />
                you are looking for.
              </Typography>
              <Typography variant="h6" textAlign={"center"} color={"gray"}>
                You can get back to the Home page <br />
                by clicking the button below.
              </Typography>
              <Box>
                <Button
                  sx={{ borderRadius: 6 }}
                  size="medium"
                  startIcon={<HomeIcon />}
                  onClick={() => {
                    navigate("/home");
                  }}
                  variant="contained"
                >
                  Back to Home
                </Button>
              </Box>
            </Box>
          </Box>
          <Box width={{ xs: "100%", md: "40%" }}>
            <Lottie
              options={defaultOptions}
              width={"100%"}
              isClickToPauseDisabled
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default ErrorPage;
