import { useContext, createContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  updateProfile,
  verifyBeforeUpdateEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from "firebase/auth";
import { auth } from "../firebase-config";
import LoadingSection from "../components/LoadingSection";
import { getServerTime } from "../utils/serverTime";
import { addUserInfo } from "../services/user";

const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [serverTime, setServerTime] = useState(null);
  const [token, setToken] = useState("");
  const [pending, setPending] = useState(true);

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password).then(
      async (userCre) => {
        const { uid, displayName, email, metadata, phoneNumber, photoURL } =
          userCre.user;
        const { creationTime, lastSignInTime, createdAt, lastLoginAt } =
          metadata;

        let body;
        body = {
          displayName: displayName,
          email: email,
          createdAt: createdAt,
          lastLoginAt: lastLoginAt,
          phoneNumber: phoneNumber,
          photoURL: photoURL,
        };

        await addUserInfo(uid, body);
      }
    );
    // .catch((error) => {
    //   console.log("E::", error);
    // });
  }

  function signIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password).then(
      async (userCre) => {
        const { uid, displayName, email, metadata, phoneNumber, photoURL } =
          userCre.user;
        const { creationTime, lastSignInTime, createdAt, lastLoginAt } =
          metadata;

        let body;
        body = {
          displayName: displayName,
          email: email,
          createdAt: createdAt,
          lastLoginAt: lastLoginAt,
          phoneNumber: phoneNumber,
          photoURL: photoURL,
        };

        await addUserInfo(uid, body);
      }
    );
    // .catch((error) => {
    //   console.log("E::", error);
    // });
  }

  function googleSignIn() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider).then(async (userCre) => {
      const { uid, displayName, email, metadata, phoneNumber, photoURL } =
        userCre.user;
      const { creationTime, lastSignInTime, createdAt, lastLoginAt } = metadata;

      let body;
      body = {
        displayName: displayName,
        email: email,
        createdAt: createdAt,
        lastLoginAt: lastLoginAt,
        phoneNumber: phoneNumber,
        photoURL: photoURL,
      };

      await addUserInfo(uid, body);
    });
    // .catch((error) => {
    //   console.log("E::", error);
    // });
  }

  function logOut() {
    return signOut(auth);
  }

  function updateName(name) {
    return updateProfile(auth.currentUser, { displayName: name });
  }
  function updateEmailUser(email) {
    // return updateEmail(auth.currentUser, email);
    return verifyBeforeUpdateEmail(auth.currentUser, email);
  }
  function updatePasswordUser(password) {
    return updatePassword(auth.currentUser, password);
  }

  function reauthWithGoogle() {
    const googleProvider = new GoogleAuthProvider();
    return reauthenticateWithPopup(auth.currentUser, googleProvider);
  }
  function reauthWithEmail(password) {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    );
    return reauthenticateWithCredential(auth.currentUser, credential);
  }

  async function getToken() {
    const token = await auth.currentUser.getIdToken();
    return token;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      // getServerTime().then((res) => setServerTime(res));
      setPending(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  if (pending) {
    return (
      <>
        <LoadingSection status={pending} />
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        getToken,
        signUp,
        signIn,
        googleSignIn,
        logOut,
        updateName,
        updateEmailUser,
        updatePasswordUser,
        serverTime,
        reauthWithEmail,
        reauthWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function UserAuth() {
  return useContext(AuthContext);
}
