import { Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";

import Protected from "../auth/Protected";
import UserLogin from "../auth/UserLogin";
import UserProject from "../auth/UserProject";

import { AuthContextProvider } from "../context/AuthContext";
import { InvoiceContextProvider } from "../context/InvoiceContext";
import { VendorContextProvider } from "../context/VendorContext";
import { SubscriptionContextProvider } from "../context/SubscriptionContext";

import LoadingSection from "../components/LoadingSection";

import Login from "../views/Login";
import Register from "../views/Register";
import ErrorPage from "../views/error";

const Home = lazy(() => import("../views/Home"));
const Profile = lazy(() => import("../views/Profile"));
const Summary = lazy(() => import("../views/Summary"));
const Subscription = lazy(() => import("../views/Subscription"));
const Calendar = lazy(() => import("../views/Calendar"));
const ProjectDetails = lazy(() => import("../views/ProjectDetails"));
const EditProject = lazy(() => import("../views/EditProject"));
const ProjectPublic = lazy(() => import("../views/ProjectPublic"));
const Personal = lazy(() => import("../views/profile/Personal"));
const Partnership = lazy(() => import("../views/profile/Partnership"));
const Invoice = lazy(() => import("../views/profile/Invoice"));
const Password = lazy(() => import("../views/profile/Password"));

function Router() {
  return (
    <div>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route
            path="/home"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <SubscriptionContextProvider>
                    <InvoiceContextProvider>
                      <VendorContextProvider>
                        <Home />
                      </VendorContextProvider>
                    </InvoiceContextProvider>
                  </SubscriptionContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/summary"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <SubscriptionContextProvider>
                    <VendorContextProvider>
                      <Summary />
                    </VendorContextProvider>
                  </SubscriptionContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/profile/personal"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <SubscriptionContextProvider>
                    <InvoiceContextProvider>
                      <VendorContextProvider>
                        <Profile>
                          <Personal />
                        </Profile>
                      </VendorContextProvider>
                    </InvoiceContextProvider>
                  </SubscriptionContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/profile/password"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <SubscriptionContextProvider>
                    <InvoiceContextProvider>
                      <VendorContextProvider>
                        <Profile>
                          <Password />
                        </Profile>
                      </VendorContextProvider>
                    </InvoiceContextProvider>
                  </SubscriptionContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/profile/partnership"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <SubscriptionContextProvider>
                    <InvoiceContextProvider>
                      <VendorContextProvider>
                        <Profile>
                          <Partnership />
                        </Profile>
                      </VendorContextProvider>
                    </InvoiceContextProvider>
                  </SubscriptionContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/profile/invoice"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <SubscriptionContextProvider>
                    <InvoiceContextProvider>
                      <VendorContextProvider>
                        <Profile>
                          <Invoice />
                        </Profile>
                      </VendorContextProvider>
                    </InvoiceContextProvider>
                  </SubscriptionContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/subscription"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <SubscriptionContextProvider>
                    <InvoiceContextProvider>
                      <VendorContextProvider>
                        <Subscription />
                      </VendorContextProvider>
                    </InvoiceContextProvider>
                  </SubscriptionContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/project/:idProj"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <SubscriptionContextProvider>
                    <InvoiceContextProvider>
                      <ProjectDetails />
                    </InvoiceContextProvider>
                  </SubscriptionContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/project/:idProj/edit"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <SubscriptionContextProvider>
                    <VendorContextProvider>
                      <EditProject />
                    </VendorContextProvider>
                  </SubscriptionContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/calendar"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <SubscriptionContextProvider>
                    <VendorContextProvider>
                      <Calendar />
                    </VendorContextProvider>
                  </SubscriptionContextProvider>
                </Suspense>
              </Protected>
            }
          />

          <Route
            path="/public/:encryptId"
            element={
              <UserProject>
                <ProjectPublic />
              </UserProject>
            }
          />
          <Route
            path="/signup"
            element={
              <UserLogin>
                <Register />
              </UserLogin>
            }
          />
          <Route
            path="*"
            element={
              <Protected>
                <ErrorPage />
              </Protected>
            }
          />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default Router;
