import React from "react";
import { Backdrop, Box } from "@mui/material";

import LoadingAnim from "../assets/anim/load.json";
import Lottie from "react-lottie";

const LoadingSection = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Backdrop
      sx={{
        bgcolor: "#fff",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={props.status}
    >
      <Box
        // width={{ xs: "40%", sm: "20%", md: "15%", lg: "15%", xl: "10%" }}
        width={{ xs: "70%", md: "30%" }}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingBottom={12}
      >
        <Box position={"relative"} width={"100%"} height={{ xs: 200, md: 400 }}>
          <Lottie options={defaultOptions} width={"100%"} />
          <Box
            sx={{
              width: "70%",
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
            component="img"
            display="flex"
            width={"100%"}
            alt="NOTA Logo"
            src="/logo-h.png"
          />
        </Box>
      </Box>
    </Backdrop>
  );
};

export default LoadingSection;
