import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LogoHeader from "../assets/logo/logo-brand-h.png";
import { UserAuth } from "../context/AuthContext";
import { Drawer, Box, Chip } from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import FaceIcon from "@mui/icons-material/Face";
import MenuIcon from "@mui/icons-material/Menu";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import WidgetsIcon from "@mui/icons-material/Widgets";
import { grey, teal } from "@mui/material/colors";
import { UserSubscription } from "../context/SubscriptionContext";
import { formatMonthShort } from "../utils/dateFormat";

const Navbar = () => {
  const { isActive, validUntil } = UserSubscription();
  let navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const { logOut, user } = UserAuth();

  const handleSignOut = async () => {
    try {
      await logOut();
      sessionStorage.setItem("isLogin", false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <Box>
      <Toolbar disableGutters>
        <Box
          component="img"
          sx={{
            width: 130,
            mr: 1,
            display: { xs: "none", md: "flex" },
          }}
          alt="NOTA Logo"
          src={LogoHeader}
        />

        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => setOpenSidebar(true)}
            color="inherit"
          >
            <MenuIcon color="fourth" />
          </IconButton>
          <Drawer
            anchor="left"
            open={openSidebar}
            onClose={() => setOpenSidebar(false)}
          >
            <Box width={250} textAlign="left" role="presentation">
              <Box
                component="img"
                sx={{
                  py: 1,
                  px: 2,
                  width: "100%",
                  objectFit: "cover",
                }}
                alt="Logo Header"
                src={LogoHeader}
              />
              <Divider />
              <List sx={{ p: 0 }}>
                <NavLink className={"menu"} to={"/home"}>
                  {({ isActive, isPending, isTransitioning }) => (
                    <ListItem
                      sx={{
                        bgcolor: isActive ? "primary.main" : "transparent",
                        color: isActive ? "white" : "primary.main",
                      }}
                      secondaryAction={
                        <IconButton edge="end">
                          <KeyboardArrowRightIcon
                            sx={{
                              color: isActive ? "white" : "primary.main",
                            }}
                          />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <HomeIcon
                            sx={{
                              color: isActive ? "white" : "primary.main",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>HOME</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  )}
                </NavLink>

                <a
                  href="https://weddlist.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="menu"
                >
                  <ListItem
                    sx={{
                      color: "primary.main",
                    }}
                    secondaryAction={
                      <IconButton edge="end">
                        <KeyboardArrowRightIcon
                          sx={{ color: "primary.main" }}
                        />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <InfoIcon sx={{ color: "primary.main" }} />
                      </ListItemIcon>
                      <ListItemText>ABOUT US</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </a>
                <NavLink className={"menu"} to={"/calendar"}>
                  {({ isActive, isPending, isTransitioning }) => (
                    <ListItem
                      sx={{
                        bgcolor: isActive ? "primary.main" : "transparent",
                        color: isActive ? "white" : "primary.main",
                      }}
                      secondaryAction={
                        <IconButton edge="end">
                          <KeyboardArrowRightIcon
                            sx={{
                              color: isActive ? "white" : "primary.main",
                            }}
                          />
                        </IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <CalendarIcon
                            sx={{
                              color: isActive ? "white" : "primary.main",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>CALENDAR</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  )}
                </NavLink>

                <a
                  href="https://api.whatsapp.com/send?phone=6285190113941&text=Hello%20I%20need%20some%20information!"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="menu"
                >
                  <ListItem
                    sx={{
                      color: "primary.main",
                    }}
                    secondaryAction={
                      <IconButton edge="end">
                        <KeyboardArrowRightIcon
                          sx={{ color: "primary.main" }}
                        />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <QuestionAnswerIcon sx={{ color: "primary.main" }} />
                      </ListItemIcon>
                      <ListItemText>HELP & SUPPORT</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </a>
              </List>
            </Box>
          </Drawer>
        </Box>

        <Box
          flexGrow={1}
          display={{ xs: "flex", md: "none" }}
          justifyContent="center"
          paddingX={2}
          alignItems="center"
        >
          <Box
            component="img"
            display="flex"
            width={130}
            alt="NOTA Logo"
            src={LogoHeader}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: { xs: "none", md: "flex" },
            my: 2,
            gap: 2,
          }}
        >
          <NavLink className={"menu"} to={"/home"}>
            {({ isActive, isPending, isTransitioning }) => (
              <Button
                variant={isActive ? "contained" : "text"}
                onClick={handleCloseNavMenu}
                sx={{ display: "block", borderRadius: 3 }}
              >
                HOME
              </Button>
            )}
          </NavLink>

          <a
            href="https://weddlist.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="menu"
          >
            <Button
              variant="text"
              onClick={handleCloseNavMenu}
              sx={{ display: "block" }}
            >
              About Us
            </Button>
          </a>

          {/* <Button
            variant="outlined"
            disabled
            onClick={handleCloseNavMenu}
            sx={{ display: "flex", flexDirection: "column", borderRadius: 3 }}
          >
            SUMMARY
            <Chip
              color={"error"}
              label={"Coming Soon"}
              sx={{
                fontSize: 8,
                height: 20,
                // width: 100,
                textTransform: "uppercase",
              }}
            />
          </Button> */}
          <NavLink className={"menu"} to={"/calendar"}>
            {({ isActive, isPending, isTransitioning }) => (
              <Button
                variant={isActive ? "contained" : "text"}
                onClick={handleCloseNavMenu}
                sx={{ display: "block", borderRadius: 3 }}
              >
                CALENDAR
              </Button>
            )}
          </NavLink>

          <a
            href="https://api.whatsapp.com/send?phone=6285190113941&text=Hello%20I%20need%20some%20information!"
            target="_blank"
            rel="noopener noreferrer"
            className="menu"
          >
            <Button variant="text" sx={{ display: "block" }}>
              Help & Support
            </Button>
          </a>
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open Menu" arrow placement="bottom-start">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {user.photoURL ? (
                <Avatar alt={user.displayName} src={user.photoURL} />
              ) : (
                <Box
                  width={42}
                  height={42}
                  border={1}
                  borderColor="eighth.main"
                  borderRadius={100}
                  bgcolor="fifth.main"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FaceIcon color="fourth" />
                </Box>
              )}
            </IconButton>
          </Tooltip>
          <Menu
            // sx={{ mt: "-15px", ml: "10px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            PaperProps={{
              style: {
                width: 150,
              },
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              borderBottom={1}
              borderColor={grey["300"]}
              gap={0.5}
              px={2}
              py={1}
            >
              <Typography
                variant="caption"
                fontWeight={600}
                textTransform={"uppercase"}
              >
                Subscription
              </Typography>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography variant="caption">
                  Status:{" "}
                  {isActive ? (
                    <span style={{ color: "green" }}>ACTIVE</span>
                  ) : (
                    <span style={{ color: "red" }}>NOT ACTIVE</span>
                  )}
                </Typography>
                {isActive && (
                  <Typography variant="caption">
                    Expired: {formatMonthShort(validUntil)}
                  </Typography>
                )}
              </Box>
            </Box>
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                navigate("/profile/personal");
              }}
            >
              <Typography textAlign="left">Profile</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCloseUserMenu();
                navigate("/subscription");
              }}
            >
              <Typography textAlign="left">Subscription</Typography>
            </MenuItem>
            <MenuItem onClick={handleSignOut}>
              <Typography textAlign="left">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </Box>
  );
};

export default Navbar;
